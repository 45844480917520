import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Flex, Box } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
export const query = graphql`
  query {
    portraits: directusMediaCollection(name: {eq: "geschaeftsstelle"}) {
      media {
        file {
          localFile {
            name
            childImageSharp {
              fixed(width: 105, height: 120, quality: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Img = makeShortcode("Img");
const Separator = makeShortcode("Separator");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Geschäftsstelle`}</h1>
      <Flex flexWrap='wrap' alignItems='stretch' justifyContent='space-between' style={{
        textAlign: 'center'
      }} mdxType="Flex">
        <Box width={['100%', '30%', '30%', '30%']} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between'
        }} mdxType="Box">
          <h3 {...{
            "id": "leiter-der-geschaftsstelle"
          }}>{`Leiter der Geschäftsstelle`}</h3>
          <Img fixed={props.data.portraits.media[1].file.localFile.childImageSharp.fixed} mdxType="Img" />
          <p><strong parentName="p">{`Siegfried H.W. Schierstedt`}</strong><br />{`
`}<a parentName="p" {...{
              "href": "mailto:sschierstedt@fvdh.de",
              "rel": "nofollow noopener noreferrer external"
            }}>{`sschierstedt@fvdh.de`}</a></p>
        </Box>
        <Box width={['100%', '30%', '30%', '30%']} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between'
        }} mdxType="Box">
          <h3 {...{
            "id": "sekretariat--mitgliederbetreuung"
          }}>{`Sekretariat & Mitgliederbetreuung`}</h3>
          <Img fixed={props.data.portraits.media[0].file.localFile.childImageSharp.fixed} mdxType="Img" />
          <p><strong parentName="p">{`Sabine Wehner`}</strong><br />{`
`}<a parentName="p" {...{
              "href": "mailto:swehner@fvdh.de",
              "rel": "nofollow noopener noreferrer external"
            }}>{`swehner@fvdh.de`}</a></p>
        </Box>
        <Box width={['100%', '30%', '30%', '30%']} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between'
        }} mdxType="Box">
          <h3 {...{
            "id": "buchhaltung"
          }}>{`Buchhaltung`}</h3>
          <p><a parentName="p" {...{
              "href": "mailto:mitgliederverwaltung@fvdh.de",
              "rel": "nofollow noopener noreferrer external"
            }}>{`mitgliederverwaltung@fvdh.de`}</a></p>
        </Box>
      </Flex>
      <Separator mdxType="Separator" />
      <Card mdxType="Card">
        <p><strong parentName="p">{`FVDH – Freier Verband Deutscher Heilpraktiker e.V. `}<br />{`
Bundesgeschäftsstelle`}</strong></p>
        <p>{`Weseler Str. 19 – 21 `}<br />{`
48151 Münster`}</p>
        <p>{`Tel.: 0251 – 136886`}<br />{`
Fax: 02 51 – 392736`}<br />{`
E-Mail: `}<a parentName="p" {...{
            "href": "mailto:info@fvdh.de",
            "rel": "nofollow noopener noreferrer external"
          }}>{`info@fvdh.de`}</a></p>
      </Card>
      <Separator mdxType="Separator" />
      <h2 {...{
        "id": "burozeiten"
      }}>{`Bürozeiten`}</h2>
      <p><strong parentName="p">{`Mo.- Do.:`}</strong><br />{`
09:00 - 15:00 Uhr durchgehend erreichbar`}</p>
      <p><strong parentName="p">{`Fr.:`}</strong><br />{`
09:00 – 12:00 Uhr erreichbar`}</p>
      <b>
  Während der Schulferien in Nordrhein-Westfalen haben wir geänderte Bürozeiten:
  Montag, Dienstag und Donnerstag von 09:00 – 15:00 Uhr sowie Mittwoch und Freitag von 09:00 – 12:00 Uhr
      </b>
      <Separator mdxType="Separator" />
      <h2 {...{
        "id": "verbands-hotline-zum-vorstand-fur-den-aktuellen-span-stylecolor-e74c3cnotfallspan-fur-praxisinhaber-ausserhalb-der-burozeiten"
      }}>{`Verbands-HOTLINE zum Vorstand für den aktuellen `}<span style={{
          color: '#e74c3c'
        }}>{`Notfall`}</span>{` für Praxisinhaber außerhalb der Bürozeiten:`}</h2>
      <p>{`Siegfried H. W. Schierstedt und Matthias Mertler`}</p>
      <p>{`S. Schierstedt: 0172 1714111`}<br />{`
M. Mertler:	0173 5169110`}</p>
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      